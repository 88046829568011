

const webp_imgs = [];
const webp_backs = [];

IMAGE_OBSERVER_CONFIG = { childList: true, subtree: true };

const mutation_callback = function(mutationsList, observer) {
    for(const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        for (let anode of mutation.addedNodes) {
          if (anode.tagName == 'IMG' && anode.src.endsWith(".webp")) {
            const osrc = anode.src
            webp_imgs.push(anode);
          } else {
            if (anode instanceof Element || anode instanceof HTMLDocument) {
              const nbimg = window.getComputedStyle(anode).getPropertyValue('background-image');
              if (nbimg.startsWith('url("') && nbimg.endsWith('.webp")')) {
                webp_backs.push({
                  node: anode,
                  bgstr: nbimg.slice(0, -6)+'png")'
                });
              }
            }
          }
        }
      }
    }
};

IMAGE_OBSERVER = new MutationObserver(mutation_callback);

window.addEventListener("load", async function (e) {
  try {

    const webp_support = await new Promise((out) => {
      let test_webp = document.createElement("img");
      test_webp.addEventListener("error", (evt) => {
        out(false)
      });
      test_webp.addEventListener("load", (evt) => {
        out(true)
      });
      test_webp.src = "/g/res/test-support.webp"
    });

    IMAGE_OBSERVER.disconnect();
    
    if (!webp_support) {
      for (let anode of webp_imgs) {
        await new Promise((out) => {
          const orig = anode.src;
          anode.src = anode.src.slice(0, -4)+"png";
          anode.addEventListener("load", (evt) => {
            console.log("LOADED", anode.src);
            out();
          });
        });
      }
  
      for (let bak of webp_backs) {
        bak.node.style.backgroundImage = bak.bgstr;
      }
    }

  } catch (err) {
    console.error(err);
  }
});

